import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { Template, HalfHeader } from 'common/layout';
import { TextBlock } from 'modules/contentBlocks';
import DocumentHeaderImage from 'images/SaintsandStars-Terms.jpg';

const DocumentPage: React.FC<DocumentPageProps> = ({ data }) => {
  const pageData = data?.contentfulWebsiteDocuments;
  const body = pageData?.bodyText as i.RTEType;

  if (!pageData) return null;

  return (
    <Template>
      <HalfHeader
        subtitle={pageData.title}
        imageUrl={DocumentHeaderImage}
        marginBottom={0}
      />
      <TextBlock
        content={{
          alignment: 'left',
          body: body,
          id: pageData?.id,
          __typename: 'ContentfulComponentTextBlock',
        }}
      />
    </Template>
  );
};

export default DocumentPage;

export const query = graphql`
  query DocumentPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulWebsiteDocuments(id: {eq: $id}, node_locale: {eq: $language}) {
      title
      id
      bodyText {
        raw
      }
    }
  }
`;

type DocumentPageProps = {
  data: GatsbyTypes.DocumentPageQuery;
};
